import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import './Navbar.css';

const NavBar=()=>{
    const[prevNavState, setNavBar]= useState(false)

    useEffect(()=>{
     
    },[])


    const changeNav=()=>{
        let nav = $('.navbar')

        if(window.scrollY > nav.height() ){
           setNavBar(true)
        }else{
           setNavBar(false)
        }
    }

    window.addEventListener('scroll', changeNav)

    return(
        <nav className={`navbar navbar-expand-md py-1 sticky-top ${prevNavState ? 'scrolled-active ' : ''}`}>
            <div className="container">
                <Link className="navbar-brand" to="/"><img style={{width:'80px'}} srcSet="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1627727465/Group_qtaoie.svg" alt="brand-logo"/></Link>
                
                <button className="navbar-fixed-side navbar-fixed-side-left" role="navigation" type="button" data-toggle="collapse" data-target="#navlinks" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><i className="fas fa-bars" style={{color: '#F95B42',fontSize:'28px'}}></i></span>
                </button>

                <div className="collapse navbar-collapse" id="navlinks">
                    <ul className="navbar-nav ml-auto">

                        <li className="nav-item pl-2 pl-md-0 mr-md-3">
                            <Link className="nav-link"to="/">Home</Link>
                        </li>

                        <li className="nav-item pl-2 pl-md-0 ml-md-1">
                            <a className="nav-link" target="_blank" href="https://link.medium.com/vpnXjGOmMqb">Blog</a>
                        </li>


                        <li className="nav-item pl-2 ml-md-2 mr-md-4">
                           <a className="nav-link" href="https://docs.google.com/forms/d/e/1FAIpQLSfrsx_JxPodF_fN3RiukhdXWNj2HDDh-SzDZbDROjRqCBvrEA/viewform" target="_blank">Give feedback!</a>
                        </li>


                        <li className="nav-item pl-2 pl-md-0 mr-md-3">
                            <a className="nav-link" target="_blank" href="/files/How_to_Schedule_A_Date.pdf">Faq</a>
                        </li>


                    </ul>
                </div>
            </div>
        </nav> 
    )

}
export default NavBar;