import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import axios from 'axios';
import firebase from 'firebase';
import './Footer.css';

const Footer=()=>{
    const[prevState, setForm]= useState({email:""})


    useEffect(()=>{
        
    },[])

    //This could be changed to an axios request to a datenight back-end endpoint
    //Once i start building - Damola o

    const onFormSubmit=(e)=>{
        e.preventDefault();

        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_APP_ID ,
            measurementId: process.env.REACT_APP_MEASUREMENT_ID 
        };
        
        firebase.initializeApp(firebaseConfig);

        let email = document.querySelector(".input-email");
       
        const db = firebase.firestore()

        db.collection("userSubEmail").add({
                email: email.value
            })
            .then(function(docRef) {
                console.log("Document written with ID: ", docRef.id);
                alert("Email subscribed successfully !");
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
                alert(error)
        });
    }


    return(

    <footer className="footer">
        <div className="container">

            <div className="row pt-3 justify-content-center">
                <div className="col-md-12">
                    <p className="text-center text-white">Powered by<br/><img className="ims-btm-logo" srcSet="https://res.cloudinary.com/dayvbcxai/image/upload/v1608066968/DateNight/immersia-logo-removebg-preview_1_qf8hn0.svg" alt="bottom_brand_logo"/></p>
                    
                </div>
            </div>
        
            <div className="row justify-content-center py-2">
                <div className="col-lg-4">
                    <ul className="list-inline d-flex flex-column ims-ftr-colmn-one">
                        <li className="ml-md-2 py-2 mt-4">United Kingdom</li>
                        <a className="ml-md-2 py-2 email" href="mailto:info@immersia.co.uk">info@immersia.co.uk</a>
                        <li className="ml-md-2 py-2">REG NO: <span className="reg-no">12726424</span></li>
                    </ul>
                </div>


                <div className="col-lg-4">
                    <ul className="list-inline d-flex flex-column py-2 ims-ftr-colmn-two ">
                        {/* <a href="/files/PrivacyPolicy.pdf" className="py-2 ftr-colmn-two-link" download="Privacy Policy">PRIVACY POLICY</a> */}
                        <Link  className="py-2 ftr-colmn-two-link" to={`/privacy`}>PRIVACY POLICY</Link>
                        <a href="/files/Terms and conditions for supply of services to consumers via a website.pdf" className="ftr-colmn-two-link py-2" download="Terms of use">TERMS AND CONDITIONS</a>
                    </ul>
                </div>

                <div className="col-lg-4">
                    <h6 className="mt-4">Join our community</h6>

                    <form onSubmit={onFormSubmit} method="POST">
                        <input type="email" name="email" className="form-control input-email mt-4"  value={prevState.email} onChange={(e)=>setForm({...prevState, email:e.target.value})} placeholder="Email Address"  required/>
                        <button data-micron="pop" className="ims-send-email border-0" type="submit" style={{backgroundImage:"url(https://res.cloudinary.com/dayvbcxai/image/upload/v1608133532/DateNight/Vector_1_vk0wqx.svg)",width:"33px",backgroundRepeat:"no-repeat",outline:"none"}}></button>
                    </form>

                    <ul className="list-inline mb-0 mt-4">
                        <span className="socials">
                            
                            <a className="list-inline-item pr-4 pr-md-3" href="https://www.instagram.com/datenightapp_/" target="_blank"><img srcSet="https://res.cloudinary.com/dayvbcxai/image/upload/v1608067082/DateNight/cib_instagram_upberq.svg" alt=""/></a>
                            <a className="list-inline-item px-3 px-md-3" href="https://twitter.com/datenightapp_" target="_blank"><img srcSet="https://res.cloudinary.com/dayvbcxai/image/upload/v1608067083/DateNight/teenyicons_twitter-outline_sqifo1.svg" alt=""/></a>
                            <a className="list-inline-item px-3 px-md-3" href="https://discord.gg/9zaFCqh3A8" target="_blank"><img srcSet="https://res.cloudinary.com/www-softroniiks-com/image/upload/c_scale,q_auto:best,w_40/v1646338641/discord-logo_agb80k.png" alt=""/></a>
                            
                        </span>
                    </ul>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-md-12">
                    <p className="text-center btm-cop-right my-3 my-md-0">&#169; 2024 Immersia Ltd. All rights reserved</p>
                </div>
            </div>
        </div>
    </footer>
    )

}

export default Footer;