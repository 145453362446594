import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import '../Home/Home.css'

const Privacy = ()=>{
    return(
        <>
            <NavBar/>
            <div className="container">
                <h1>PRIVACY POLICY</h1>
                <p>This privacy policy applies between you, the User of this Website, and Immersia Ltd, the owner and provider of this Website. Immersia Ltd takes the privacy of your information very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website. This privacy policy should be read alongside, and in addition to, our Terms and Conditions, which can be found at: Immersia.co.uk. Please read this privacy policy carefully.</p>
                
                <h2>Definitions and interpretation</h2>
                <p>In this privacy policy, the following definitions are used:</p>
                <ul>
                    <li><strong>Data:</strong> collectively all information that you submit to Immersia Ltd via the Website.</li>
                    <li><strong>Data Protection Laws:</strong> any applicable law relating to the processing of personal Data, including but not limited to the GDPR.</li>
                    <li><strong>GDPR:</strong> the General Data Protection Regulation (EU) 2016/679.</li>
                    <li><strong>Immersia Ltd, we, or us:</strong> Immersia Ltd, a company incorporated in England and Wales with registered number 12726424.</li>
                    <li><strong>User or you:</strong> any third party that accesses the Website and is not employed by Immersia Ltd or engaged as a consultant or otherwise providing services to Immersia Ltd.</li>
                    <li><strong>Website:</strong> the website that you are currently using, Immersia.co.uk, and any sub-domains of this site.</li>
                </ul>
                
                <h2>Scope of this privacy policy</h2>
                <p>This privacy policy applies only to the actions of Immersia Ltd and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites.</p>
                
                <h2>Data collected</h2>
                <p>We may collect the following Data, which includes personal Data, from you:</p>
                <ul>
                    <li>name;</li>
                    <li>date of birth;</li>
                    <li>contact Information such as email addresses and telephone numbers;</li>
                    <li>financial information such as credit / debit card numbers;</li>
                    <li>IP address (automatically collected);</li>
                    <li>photo;</li>
                </ul>
                <p>In each case, in accordance with this privacy policy.</p>
                
                <h2>How we collect Data</h2>
                <p>We collect Data in the following ways:</p>
                <ul>
                    <li>data is given to us by you;</li>
                    <li>data is collected automatically.</li>
                </ul>
                
                <h2>Data that is given to us by you</h2>
                <p>Immersia Ltd will collect your Data in a number of ways, for example:</p>
                <ul>
                    <li>when you contact us through the Website, by telephone, post, e-mail or through any other means;</li>
                    <li>when you register with us and set up an account to receive our products/services;</li>
                    <li>when you enter a competition or promotion through a social media channel;</li>
                    <li>when you make payments to us, through this Website or otherwise;</li>
                    <li>when you use our services;</li>
                </ul>
                <p>In each case, in accordance with this privacy policy.</p>

                <h2>Transfers outside the European Economic Area</h2>
                <p>Data which we collect from you may be stored and processed in and transferred to countries outside of the European Economic Area (EEA). For example, this could occur if our servers are located in a country outside the EEA or one of our service providers is situated in a country outside the EEA. We will only transfer Data outside the EEA where it is compliant with data protection legislation and the means of transfer provides adequate safeguards in relation to your data, eg by way of data transfer agreement, incorporating the current standard contractual clauses adopted by the European Commission, or by signing up to the EU-US Privacy Shield Framework, in the event that the organisation in receipt of the Data is based in the United States of America. To ensure that your Data receives an adequate level of protection, we have put in place appropriate safeguards and procedures with the third parties we share your Data with. This ensures your Data is treated by those third parties in a way that is consistent with the Data Protection Laws.</p>
                
                <h2>Links to other websites</h2>
                <p>This Website may, from time to time, provide links to other websites. We have no control over such websites and are not responsible for the content of these websites. This privacy policy does not extend to your use of such websites. You are advised to read the privacy policy or statement of other websites prior to using them.</p>
                
                <h2>Changes of business ownership and control</h2>
                <p>Immersia Ltd may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of control of all or part of Immersia Ltd. Data provided by Users will, where it is relevant to any part of our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this privacy policy, be permitted to use the Data for the purposes for which it was originally supplied to us. We may also disclose Data to a prospective purchaser of our business or any part of it. In the above instances, we will take steps with the aim of ensuring your privacy is protected.</p>
                
                <h2>General</h2>
                <p>You may not transfer any of your rights under this privacy policy to any other person. We may transfer our rights under this privacy policy where we reasonably believe your rights will not be affected. If any court or competent authority finds that any provision of this privacy policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this privacy policy will not be affected. Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy. This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.</p>
                
                <h2>Changes to this privacy policy</h2>
                <p>Immersia Ltd reserves the right to change this privacy policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website, and you are deemed to have accepted the terms of the privacy policy on your first use of the Website following the alterations.</p>
                
                <p>You may contact Immersia Ltd by email at <a href="mailto:hyelbolly@outlook.com">hyelbolly@outlook.com</a>.</p>
                
                <p>Attribution: This privacy policy was created using a document from Rocket Lawyer (https://www.rocketlawyer.com/gb/en). 11 December 2020</p>
        </div>
        {/* Footer */}
        <Footer/>
    </>
    )
}

export default Privacy;