import React, {useState, useEffect} from 'react';
import $ from 'jquery';
import Navbar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import './Home.css';

const Home=(props)=>{
    return(
        <div className="ims-sec-1">
            {/* Nav */}
            <Navbar/>

            {/* Sec-1 */}
            <div className="container">
                <div className="row mt-md-5">
                    
                    <div className="col-md-6">
                        <img className="date-night-logo mt-5" srcSet="https://res.cloudinary.com/dayvbcxai/image/upload/v1597180001/DateNight/DateNight-Logo-Icon-Transparetn_ms1kfn.png" alt="date_night_logo"/>
                        <img className="date-night-text-logo mt-5" srcSet="https://res.cloudinary.com/dayvbcxai/image/upload/v1597179994/DateNight/DateNight-Wordmark-Icon-Raw-files_eyh0ad.png" alt="date_night_text_logo"/>
                        <h2 className="mt-4">Recreating authentic dating experiences on mobile.</h2>

                        <p className="mt-2 ims-sec-1-p">Say good bye to <span style={{color:'#B048D1'}}>failed talking stages</span> and endless typing <img src="https://res.cloudinary.com/dayvbcxai/image/upload/v1608464388/DateNight/Group_e9lzlo.svg" alt=""/> <br/>DateNight is the only app
                            that brings you closer to that <br/> special someone more than ever before <img src="https://res.cloudinary.com/dayvbcxai/image/upload/v1608067120/DateNight/Vector_1_no6mlz.svg" alt="purple_heart"/><br/>Join in on the social revolution
                            today!
                        </p>

                        {/* <h3 className="mt-5">Access on the apple store !</h3> */}
                        {/* <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ltd_immersia_datenight"><img className="my-2" srcSet="https://res.cloudinary.com/dayvbcxai/image/upload/v1608067053/DateNight/Google_Play_Badge_oddwks.png" alt=""/></a> */}
                        <a target="_blank" href="https://apps.apple.com/us/app/datenight-app/id1546254718"><img className="ml-0 my-2" src="https://res.cloudinary.com/dayvbcxai/image/upload/v1608067053/DateNight/App_Store_Badge_eixrox.png" alt=""/></a>
                    </div>

                    <div className="col-md-6">
                        <img data-micron="shake" data-micron-duration=".95" className="app-screen-shots" src="https://res.cloudinary.com/dayvbcxai/image/upload/v1611285594/DateNight/Group_28_r3uktc.svg" alt="product_date_night"/>
                    </div>
                </div>
            </div>
            

            {/* Footer */}
            <Footer/>
        </div>
    )

}

export default Home;