import React from 'react';
import {Routes, BrowserRouter, Route} from 'react-router-dom'
import Home from '../components/Home/Home'
import Privacy from './Privacy/Privacy.jsx';
import '../components/App.css'

function App() {
  return (
	<BrowserRouter>
		<Routes>
			<Route exact path="/" element={<Home/>} />
			<Route exact path="/privacy" element={<Privacy/>} />
		</Routes>
	</BrowserRouter>	  
  );
}

export default App;
